
import { Component, Vue } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { namespace } from 'vuex-class';
import { User } from '@/models/users/user.class';
import { UserUpdate } from '@/models/users/user-update.interface';
import { ChangePassword } from '@/models/auth/change-password.class';

const usersModule = namespace('users');
const authModule = namespace('auth');

@Component({
  components: { ValidationProvider, ValidationObserver },
})
export default class LoginData extends Vue {
  showPassword = false;
  showLoading = false;
  successMessageSeconds = 0;
  request: ChangePassword = new ChangePassword();

  @authModule.Getter('authenticatedUser')
  user!: User;

  @authModule.Action('changePassword')
  changePassword!: (request: ChangePassword) => Promise<boolean | string>;

  @usersModule.Action('UPDATE')
  updateUser!: (UserUpdate: UserUpdate) => User;

  $refs!: Vue['$refs'] & {
    observer: InstanceType<typeof ValidationObserver>;
  };

  async onSubmit() {
    this.showLoading = true;
    const response = await this.changePassword(this.request);
    if (response) {
      this.$refs.observer.reset();
      this.request = new ChangePassword();
      this.successMessageSeconds = 8;
      window.scrollTo(0, 0);
    }
    this.showLoading = false;
  }
}
